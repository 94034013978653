import axios from './config'

// 获取日志列表
export function getLogsList (params) {
  return axios.get('/adminOperationLog', { params })
}

// 删除日志
export function deleteLogs (id) {
  return axios.delete(`/adminOperationLog/${id}`)
}
