<template>
<div class=''>
   <div class='log-nav border-radius-6 box-shadow-light-grey margin-top-bottom-twenty'>
      <el-menu class='height-fiftyTwo border-radius-6' default-active="1" mode='horizontal'>
       <el-menu-item class='margin-right-23' index="1" @click.native='type=1' >用户登录日志</el-menu-item>
       <el-menu-item class='margin-right-23' index="2" @click.native='type=0'>操作日志</el-menu-item>
     </el-menu>

   </div>
  <div class='part'>
     <LogData v-if='type===1' :type='type'></LogData>
     <LogDataType v-else  :type='type'></LogDataType>
  </div>
</div>
</template>

<script>
import LogData from './logData'
import LogDataType from './logDataType'
export default {
  name: 'Logging',
  data () {
    return {
      type: 1
    }
  },

  methods: {},
  components: {
    LogData,
    LogDataType
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

.el-menu-item {
  height:100%;
}
/deep/ .el-menu-item.is-active{
   border-right:0;

  // background: #fff;
}

/deep/ .el-menu-item,
.el-submenu__title {
  height:34px;
}
/deep/ .el-menu{
  height:52px !important;
  display: flex;
  align-items: center;
  .el-menu-item{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/deep/ .el-menu-item{
   font-size:16px;
}

.log-nav{
  box-sizing: border-box;
  padding:0 5px;
  background: #fff;
}

  .inner-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .part{
    box-sizing: border-box;
    // margin:10px;
  }
</style>
