<template>
  <div v-loading="loading" class=' height-calc-type2 padding-20   border-radius-6 box-shadow-light-grey' element-loading-text="数据加载中" style='background:#fff'>
    <div class='padding-bottom-20'>
          <el-row>
      <el-col :span="16">
        <el-button @click="_getLogList"
          >刷新数据</el-button
        >
        <el-button class='custom-button-margin-left' type="primary" @click="showFilter = true"
          >高级筛选</el-button
        >
        <el-button
        class='custom-button-margin-left'
          type="danger"
          :disabled="selected.length === 0"
          @click="handleDelete(selected)"
          >批量删除</el-button
        >
      </el-col>
      <el-col :span="8" style="text-align: right">
        <el-input

          placeholder="请输入用户姓名"
          v-model="userName"
          clearable
          @clear="handlePageChange(1)"
          prefix-icon="el-icon-search"
          style="width: 300px"
          @keydown.native.enter="handlePageChange(1)"
        >
          <el-button slot="append" @click="handlePageChange(1)">搜索</el-button>
        </el-input>
      </el-col>
    </el-row>

    </div>
    <div
      class=''
      style="background:#fff; "
    >
      <el-table
        :data="LogDataList"

        height='calc(100vh - 370px)'
        style="width: 100%"
        @selection-change="
          select => {
            this.selected = select;
          }
        "
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="ID" width="80"> </el-table-column>
        <el-table-column prop="userName" label="用户名称" width="80">
        </el-table-column>
        <el-table-column
          v-if="type === 0"
          prop="method"
          label="请求方式"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="path"
          v-if="type === 0"
          label="请求路径"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="ip" label="IP地址" min-width="150">
        </el-table-column>
        <el-table-column label="请求参数" min-width="200">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p style="max-width: 1200px">{{ scope.row.input }}</p>
              <div slot="reference" class="name-wrapper">
                <p class="inner-text">{{ scope.row.input }}</p>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="返回参数" min-width="200">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p style="max-width: 1200px">{{ scope.row.response }}</p>
              <div slot="reference" class="name-wrapper">
                <p class="inner-text">{{ scope.row.response }}</p>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="150">
        </el-table-column>
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-button class='button-small' type="danger" @click="handleDelete([scope.row])"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;text-align: right"
        background
        @current-change="handlePageChange"
        :page-size="15"
        :current-page="page"
        layout="total, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
       title='高级筛选'
       direction='rtl'
       size='500px'
       :visible.sync='showFilter'
    >
    <!-- <el-dialog
      center
      title="高级筛选"
      :visible.sync="showFilter"
      width="550px"
      :before-close="
        () => {
          this.showFilter = false;
        }
      "
    > -->
      <el-form :model="filter" label-width="100px">
        <el-form-item label="请求方式">
          <el-select v-model="filter.method" clearable style="width: 90%">
            <el-option value="GET">GET</el-option>
            <el-option value="POST">POST</el-option>
            <el-option value="PUT">PUT</el-option>
            <el-option value="DELETE">DELETE</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请求路径">
          <el-input v-model="filter.path"  style="width: 90%"/>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="filter.selectDate"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 90%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="showFilter = false">取 消</el-button>
        <el-button type="danger" @click="handleResetData">重 置</el-button>
        <el-button
          type="primary"
          @click="
            handlePageChange(1);
            showFilter = false;
          "
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getLogsList, deleteLogs } from '@/api/log'

export default {
  name: 'Logging',
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      loading: false,
      page: 1,
      total: 0,
      userName: '',
      showFilter: false,
      filter: {
        method: '',
        path: '',
        selectDate: []
      },
      selected: [],
      LogDataList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted () {
    this._getLogList()
  },
  methods: {
    handleDelete (selected) {
      this.$confirm('此操作将删除选择日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        const idArr = selected.map(item => item.id)
        deleteLogs(idArr.join()).then(() => {
          this.handlePageChange(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    handleResetData () {
      this.filter = {
        method: '',
        path: '',
        selectDate: []
      }
      this.handlePageChange(1)
      this.showFilter = false
    },
    handlePageChange (e) {
      this.page = e
      this._getLogList()
    },
    _getLogList () {
      this.loading = true
      const { page, userName, filter, type } = this
      const { method, path, selectDate } = filter
      const [startTime, endTime] = selectDate || []
      getLogsList({
        page,
        userName,
        method,
        path,
        startTime,
        endTime,
        type: type
      })
        .then(res => {
          const { list, total } = res
          this.LogDataList = list
          this.total = total
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.inner-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}

</style>
